<template>
  <div class="roles-manage-view">
    <div class="title">User Group Definition</div>
    <TableComponent
      :items="roleManage"
      :headers="headers"
      :iconDelete="false"
      :iconDetail="false"
      :pagination="false"
      tables="user-group-management"
      @edit="handleTurnOnModalEdit" />
    <ModalComponent
      v-if="showEdit"
      :onModalCancelEvent="handleTurnOffModalEdit"
      :onModalSaveEvent="onSubmit"
      :widthButtonFooter="181"
      :paddingFooter="24"
      :width="'96%'"
      actionRight>
      <div>
        <v-select
          class="select-user-group"
          v-model="selectedUserGroup"
          :items="filteredRoleManage"
          :menu-props="{ bottom: true, offsetY: true }"
          dense
          :readonly="false"
          item-text="user_group"
          item-value="id"
          @input="handleChangeSelectUser"
          label="Select"></v-select>
      </div>
      <div class="simple-table-container">
        <div class="table-content-checkbox">
          <SimpleTableComponent
            :items="accessible_menu.item_web"
            title="WEBSITE" />
        </div>
        <div class="table-content-checkbox">
          <SimpleTableComponent
            :items="accessible_menu.item_app"
            title="MOBILE" />
        </div>
      </div>
      <div></div>
    </ModalComponent>
    <ModalComponent
      v-if="showEditSuccess"
      titleHeader="Saved Successfully"
      :onModalCancelEvent="handleTurnOffModalEditSuccess"
      :isShowBtnSave="false"
      :widthButtonFooter="181"
      :btnCancel="'CLOSE'"
      actionRight>
      <div class="notify">Your changes have been saved successfully</div>
    </ModalComponent>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import SimpleTableComponent from '@/components/SimpleTableComponent.vue';
import TableComponent from '@/components/TableComponent.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import { NAME_WEBSITE_ROLES_MANARER, NAME_APP_ROLES_MANAGER } from '@/constants';
export default {
  name: 'RolesManageView',
  components: {
    SimpleTableComponent,
    ModalComponent,
    TableComponent,
  },
  data() {
    return {
      showEdit: false,
      showEditSuccess: false,
      titleHeader: '',
      idItem: '',
      selectedItem: null,
      headers: [
        { text: 'User Group', value: 'user_group', divider: true,  sortable: false },
        { text: 'Users', value: 'users', divider: true, sortable: false },
        { text: 'Web View', value: 'web_view', divider: true, sortable: false },
        { text: 'Web Control', value: 'web_control', divider: true, sortable: false },
        { text: 'App View', value: 'app_view', divider: true, sortable: false },
        { text: 'App Control', value: 'app_control', divider: true, sortable: false },
        { text: 'Last Update', value: 'lastUpdate', divider: true, sortable: false },
        { text: 'Action', value: 'actions', sortable: false },
      ],
      iconEdit: true,
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    ...mapGetters('roleManage', {
      roleManage: 'getterRoleManage',
      accessible_menu: 'getterAccessible',
    }),
    filteredRoleManage() {
      return this.roleManage?.length ? this.roleManage.filter(item => item.user_group !== "Superadmin") : [];
    },
  },
  methods: {

    ...mapActions('roleManage', {
      getListRole: 'getListRole',
      getListAccessible: 'getListAccessible',
      updateAccessible: 'updateAccessible',
    }),
    ...mapActions("common", ["getProfileUser"]),
    async fetchData(param) {
      await this.getListRole(param);
    },
    async fetchDataListAccessible(param) {
      await this.getListAccessible(param);
    },
    handleChangeSelectUser(selectedUserGroup){
      this.fetchDataListAccessible(selectedUserGroup);
      this.idItem = selectedUserGroup
    },
    handleTurnOnModalEdit(item) {
      this.fetchDataListAccessible(item.id);
      this.idItem = item.id;
      this.selectedUserGroup = item;
      this.showEdit = true;
    },
    async onSubmit() {
      const accessible_web = this.accessible_menu.item_web.map((item) => ({
        [item.name]: {
          view: item.view,
          action: item.action,
        },
      }));
      const accessible_app = this.accessible_menu.item_app.map((item) => ({
        [item.name]: {
          view: item.view,
          action: item.action,
        },
      }));

      const res = await this.updateAccessible({
        id: this.idItem,
        accessible_menus: {
          web: accessible_web,
          app: accessible_app,
        },
      });
      if (res?.success === true) {
        await this.getProfileUser();
        this.fetchData();
      }
      this.handleTurnOffModalEdit();
      this.showEditSuccess = true;
    },
    handleTurnOffModalEdit() {
      this.showEdit = false;
    },
    handleTurnOffModalEditSuccess() {
      this.showEditSuccess = false;
    },
    tranferItemWebSite(item) {
      const modifiedData = item.map(item => {
       const newName = NAME_WEBSITE_ROLES_MANARER[item.name] || item.name;
        return { ...item, name: newName };
        });
      return modifiedData;
    },
    tranferItemApp(item) {
      const modifiedData = item.map(item => {
       const newName = NAME_APP_ROLES_MANAGER[item.name] || item.name;
        return { ...item, name: newName };
        });
      return modifiedData;
    }
  },
};
</script>

<style>
@import "./roleManage.scss";
</style>
