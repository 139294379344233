<template>
  <div class="form-input">
    <div v-if="showLabel" class="field" :class="{'field-error-input': errorMessage}">
      <label class="label">{{ fieldLabel }}</label>
      <label v-if="fieldRequired" class="symbol-required">*</label>
    </div>
    <div class="input-form hide-field__details">
      <v-autocomplete
        v-bind="$attrs"
        :items="items"
        :placeholder="placeholder"
        :item-text="itemText"
        :item-value="itemValue"
        background-color="#555"
        chips
        small-chips
        deletable-chips
        multiple
        v-model="innerSelected"
        solo
        dense
        class="autocomplete-common"
        :class="{'error-input': errorMessage}"
      ></v-autocomplete>
      <span class="error-message">{{ errorMessage }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    showLabel: {
      type: Boolean,
      default: true,
    },
    fieldLabel: {
      type: String,
    },
    fieldRequired: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Array,
    },
    items: { type: Array, required: true },
    placeholder: { type: String },
    errorMessage: {
      type: String,
      default: "",
    },
    itemText: {
      type: String,
      required: true,
    },
    itemValue: {
      type: String,
      required: true,
    },
  },
  computed: {
    innerSelected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss">
.autocomplete-common {
  border-radius: 8px !important;
  max-width: 500px !important;

  .v-input__slot {
    &:hover {
      background: #6d6d6d !important;
    }
  }
}
.v-select.v-input--dense .v-chip {
  margin: 8px 4px !important;

}
.v-chip--select.v-chip.v-chip--clickable {
  background-color: black !important;
  .v-chip__content {
    max-width: 420px;
    color: #ffffffe5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    display: block !important;
    padding-right: 20px;
    padding-top: 1px;

    button {
      position: absolute;
      right: 3px;
      bottom: 3.5px;
    }
  }
}
.autocomplete-common .theme--light.v-list {
  background-color: #3a3a3c;
}
.mdi-checkbox-marked::before {
  color: #636366 !important;
}

.v-select__selections {
  input {
    min-height: 40px;
    padding-right: 15px !important;
  }
}

.v-select.v-select--chips.v-select--chips--small .v-select__selections {
  max-height: 150px;
  overflow: auto;
}

.v-list-item--highlighted {
  color: #656567 !important;
}

.v-list-item--active {
  color: #0f0f0f !important;
}
.v-menu__content.v-autocomplete__content::-webkit-scrollbar ,
.v-select.v-select--chips.v-select--chips--small .v-select__selections::-webkit-scrollbar{
    width: 4px;
    border-radius: 2px;
  }
  
/* Track */
.v-menu__content.v-autocomplete__content::-webkit-scrollbar-track,
.v-select.v-select--chips.v-select--chips--small .v-select__selections::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
.v-menu__content.v-autocomplete__content::-webkit-scrollbar-thumb,
.v-select.v-select--chips.v-select--chips--small .v-select__selections::-webkit-scrollbar-thumb {
  background: #636366;
  border-radius: 20px;
}
  
 /* Handle on hover */
.v-menu__content.v-autocomplete__content::-webkit-scrollbar-thumb:hover,
.v-select.v-select--chips.v-select--chips--small .v-select__selections::-webkit-scrollbar-thumb:hover {
    background: #636366;
  }
</style>
