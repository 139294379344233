<template>
  <div class="form-input">
    <div
      v-if="showLabel"
      class="field"
      :class="{ 'field-error-input': errorMessage }"
    >
      <label class="label">{{ fieldLabel }}</label>
      <label v-if="fieldRequired" class="symbol-required">*</label>
    </div>
    <div class="input-form hide-field__details">
      <v-select
        :items="items"
        :height="height"
        :menu-props="{ bottom: true, offsetY: true }"
        :placeholder="placeholder"
        dense
        background-color="#555"
        class="select-common"
        :class="{ 'error-input': errorMessage }"
        v-model="innerSelected"
        solo
      >
      </v-select>
      <span class="error-message">{{ errorMessage }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    showLabel: {
      type: Boolean,
      default: true,
    },
    fieldLabel: {
      type: String,
    },
    fieldRequired: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [String, Number],
    },
    items: { type: Array, required: true },
    placeholder: { type: String },
    height: { type: Number, default: 40 },
    errorMessage: {
      type: String,
      default: "",
    },
  },
  computed: {
    innerSelected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss">
.form-input {
  display: flex;
  align-items: flex-start;
  .field {
    flex: 1;
    display: flex;
    align-items: center;
    .label {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.10000000149011612px;
      text-align: left;
      margin: 0;
    }
    .symbol-required {
      color: red;
    }
  }

  .input-form {
    flex: 3;
  }
}

.hidden-value {
  .v-select__selections {
    .v-chip--select {
      display: none;
    }
  }
}
.select-common {
  border-radius: 8px !important;

  .v-select__selections {
    .v-select__selection--comma {
      padding: 10px 0px;
    }
  }

  .v-input__slot {
    &:hover {
      background: #6d6d6d !important;
    }
  }
}

.v-list-item:hover {
  background-color: #303032;
  color: #fff;
}

.v-list-item:hover .v-list-item__title {
  color: #D3D3D3 !important;
}

.theme--light.v-list {
  background: #3a3a3c !important;

  .v-list-item__title {
    color: #ffffffe5;
    max-width: 445px;
    line-height: 1.5rem !important;
  }
}

.theme--light.v-select .v-select__selections {
  color: #ffffffe5 !important;
}

.v-select__selections {
  input {
    cursor: pointer !important;
  }
}
</style>
